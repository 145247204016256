<template>
  <section id="login" class="login">
    <v-container>
      <v-row justify="center" class="main-row">
        <v-col align-self="center" md="4">
          <v-card elevation="2">
            <v-row justify="center" class="pt-8">
              <v-img
                contain
                max-height="500"
                max-width="500"
                src="@/assets/logo.png"
                class="ma-auto"
              ></v-img
            ></v-row>

            <v-card-title class="text-center d-inline px-8">
              <h2>Resetowanie hasła</h2>
            </v-card-title>
            <v-card-text class="px-8">
              <v-form class="pb-3" ref="form">
                <v-text-field
                  v-model="email"
                  ref="email"
                  label="Email"
                  required
                  :rules="[rules.required, rules.email]"
                  @keydown.enter="validateResetPassword"
                ></v-text-field>

                <v-btn
                  class="mt-2"
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  @click="validateResetPassword"
                  block
                  large
                >
                  Wyślij link resetowania hasła
                </v-btn>

                <v-btn
                  class="mt-4"
                  :loading="loading"
                  :disabled="loading"
                  text
                  color="primary"
                  @click="toLoginPage"
                  block
                  x-small
                >
                  Powrót do logowania
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="viewInfo" :timeout="2000" color="success">
      Link został wysłany na email
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="viewInfo = false">
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      loading: false,
      viewInfo: false,
      email: "",
      rules: {
        required: (value) => !!value || "Wymagane.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Błędny email.";
        },
      },
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        email: this.email,
      };
    },
  },
  methods: {
    validateResetPassword() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.resetPassword();
      }
    },
    async resetPassword() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/clients/forgotPass.php`,
        data: {
          email: this.email,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.viewInfo = true;
        }
      });
      this.loading = false;
    },
    toLoginPage() {
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style>
.login .main-row {
  height: 100vh;
}
</style>